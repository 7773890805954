.standardHeightWidth {
  width: 100vw;
  min-height: 80vh;
}

.centerHorizontally {
  display: flex;
  justify-content: center;
}

.fixedRight {
  right: 0;
  top: 0;
  position: fixed;
  padding: 1rem;
  height: 10rem;
  width: 10rem;
  border-bottom-left-radius: 10rem;
  background-color: #FFF2F295;
}