body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: black;
}

h3 {
  font-family: "Secular One", sans-serif;
  margin: 0;
  padding: 0;
  text-align: end;
}